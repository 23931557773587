import React from "react";
import HomePage from "../components/layout/Home";
import Takeover404 from "../components/common/404Takeover";

export default props => {
  return (
    <HomePage>
      <Takeover404 />
    </HomePage>
  );
};
